import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
import SEO from "../../components/SEO.jsx"

class Booking extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO />
        <Header
          brand="West Coast Beach Volleyball Club"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/beach2019.jpg")}>
          <div className={classes.container}>
            <GridContainer></GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <iframe
            src="https://westcoastbeachvolley.skedda.com/booking?embedded=true"
            style={{ width: "100%", height: "520px" }}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(componentsStyle)(Booking)
